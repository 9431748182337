.card {
  width: 100%;
  background-color: var(--color-primary-b-300);
  align-items: center;
  justify-content: space-between;

  &--mobile {
    border-radius: var(--border-radius-3);
    padding: 0;
  }

  &__link {
    &--mobile {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: var(--spacing-3) var(--spacing-4);
    }
  }

  &__seerates {
    background-color: var(--color-blue-200);
    border-color: var(--color-blue-200);
    color: var(--color-primary-b-500);
  }
}
