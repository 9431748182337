.highlight {
  min-height: 72px;
  color: var(--color-brand);

  .count,
  .label {
    flex-shrink: 0;
  }

  .label {
    width: min(var(--size-11), 100%);
  }
}
