.post-fetch-link {
  text-decoration: underline;
}

.button-link {
  background-color: var(--color-foreground-subtle);
  color: var(--color-secondary-b-500);
  border: none;
  border-radius: var(--border-radius-5);
  font-weight: var(--font-weight-7);
  padding: var(--spacing-1) var(--spacing-2);
}
