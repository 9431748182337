.table-tooltip {
  &__tooltip {
    --tooltip-text-color: var(--color-brand);
    --tooltip-max-width: none;

    border-radius: var(--border-radius-2) !important;
    padding: var(--spacing-3) var(--spacing-4);
    z-index: var(--layer-1);
  }

  &__content {
    min-width: var(--size-11);
  }

  &--mobile {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.long-value {
  text-wrap: wrap;
  min-width: var(--size-13);
}
