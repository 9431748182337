.dashboard-table {
  .digital-header-tooltip {
    // Fix overlapping issue with the tooltip
    z-index: var(--layer-2);
  }

  .chip,
  td.column-nowrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--mobile {
      max-width: 12rem;
    }
  }

  // Can't be apply on the cell directly because the overflow property is hidding the filters menu
  th.column-nowrap {
    :global .cell__content > [data-testid="typography"],
    :global .cell__content > [data-testid="sort-button"] {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__footer-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    td {
      width: 100dvw;
    }
  }
}

.empty-state {
  margin-block: var(--spacing-6);
}
