.highlights-card {
  --card-padding: var(--spacing-4) var(--spacing-6);
  --card-shadow: none;
  --card-shadow-hover: none;

  display: flex;
  gap: var(--spacing-7);
  flex-wrap: wrap;
  border: var(--border-width-1) solid var(--color-gray-200);
  background-color: var(--color-background-muted);
}
