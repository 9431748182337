@use "@shared/ui/styles/breakpoints";

.table-tabs {
  width: 100%;
  align-items: flex-start;
  gap: var(--spacing-6);

  &--mobile {
    gap: var(--spacing-3);
  }

  .tab {
    min-width: var(--size-11);

    &:hover {
      text-decoration: none;

      &:not(.tab--active) {
        border-color: var(--color-primary-b-700);
        color: var(--color-primary-b-700);
      }
    }

    &--mobile {
      min-width: auto;
      padding-inline: var(--spacing-4);
    }

    &--active {
      border-color: var(--color-primary-b-500);
      color: var(--color-primary-b-500);
    }
  }

  .tablist {
    &--mobile {
      min-width: auto;
      justify-content: space-between;
    }

    &__wrapper {
      &--mobile {
        width: 100%;
      }
    }
  }

  .pannels {
    &--mobile {
      border: 1px solid var(--color-foreground-subtle);
      border-radius: var(--border-radius-2);
      overflow-x: auto;
    }
  }

  .panel {
    padding: var(--spacing-0);

    &--mobile {
      // we need to set the height to make table panel element scrollable inside
      // 23.5rem here is the height of of the elements above the table
      // so table will not be bigger than viewport
      height: calc(100vh - 23.5rem);
    }
  }
}

.scroll-area {
  @include breakpoints.md {
    height: calc(100vh - 7.5rem);

    // override scroll area styles to enable sticky header in table
    &__viewport {
      overflow-y: scroll !important;
    }
  }
}
