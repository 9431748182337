@use "@shared/ui/styles/breakpoints";

.layout {
  @include breakpoints.md {
    aside {
      &[class*="sidebar--narrow"] + main {
        min-width: auto;
        width: calc(100% - 48px);
      }

      /* Target 'main' only if the 'aside' does not have a class starting with 'sidebar--narrow' */
      &:not([class*="sidebar--narrow"]) + main {
        min-width: auto;
        width: calc(100% - 270px);
      }
    }
  }
}

.clipped-content {
  @include breakpoints.lg {
    --content-width: min(var(--size-xl), 100%);
  }
}
